<template>
  <img src="../../assets/images/login_bg.png" class="login-bg-img">
  <div class="login">
    <div class="logo">
      <div class="title">
        <img :src="logoPath" >
      </div>
<!--      <span>{{this.$store.state.unitInfo.sysName}}</span>-->
    </div>
    <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        class="demo-ruleForm"
    >
      <el-form-item label="" prop="username" class="label-color">
        <el-input v-model="ruleForm.username" placeholder="请输入账号" prefix-icon="el-icon-user" clearable></el-input>
      </el-form-item>
      <el-form-item label="" prop="password" class="label-color">
        <el-input v-model="ruleForm.password" type="password" placeholder="请输入密码" show-password
                  prefix-icon="el-icon-key" @keyup.enter="submitForm('ruleForm')" clearable></el-input>
      </el-form-item>
      <el-form-item label="" prop="code" class="label-color">
        <el-input v-model="ruleForm.code" :placeholder="codeHolder" prefix-icon="el-icon-lock"
                  @keyup.enter="submitForm('ruleForm')" clearable style="width: 67%"></el-input>
        <div class="login-code">
          <img :src="codeUrl" @click="getCode" class="login-code-img"/>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" style="width: 100%" @click="submitForm('ruleForm')" :loading="loading">
          登录
        </el-button>
      </el-form-item>
    </el-form>
  </div>
  <div class="footer">
    Copyright © 2023 美珏好太太 All Rights Reserved.
  </div>
</template>

<script>
import userApi from '@/api/system/user'
import cookie from "@/utils/cookie";
import variableApi from "@/api/system/variable";
import config from "@/api/config";
export default {
  data() {
    return {
      data:{},
      loading: false,
      codeUrl: '',
      codeHolder:'请输入验证码',
      codeType:'math',
      ruleForm: {
        username: '',
        password: '',
        code: '',
      },
      rules: {
        username: [
          {
            required: true,
            message: '请输入账号',
            trigger: 'blur',
          }
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur',
          },
        ],
        code: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur',
          },
        ],
      },
      logoPath:''
    }
  },
  methods: {
    getCode() {
      userApi.getCodeImg(this.codeType).then(response => {
        if (response.data.code == 1) {
          this.codeUrl = "data:image/gif;base64," + response.data.data.img;
        } else {
          var msg = response.data.msg;
          this.$message({
            type: 'warning',
            message: msg,
          });
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          userApi.userLogin(this.ruleForm).then((response) => {
            console.log('response', response)
            if (response.status == 200) {
              if (response.data.code == 1) {
                this.$message({
                  type: 'success',
                  message: '登录成功',
                });
                // 先清空cookie
                cookie.delCookie("token")
                cookie.delCookie("user")
                // 保存cookie
                let exdate = 60*60*24*365*100; // 超时时间，永远
                cookie.setCookie("token", response.data.data.token, exdate);
                cookie.setCookie("user", JSON.stringify(response.data.data.user), exdate);
                // 把token、user放到全局变量中
                this.$store.commit('setUser', response.data.data.user);
                this.$store.commit('setToken', response.data.data.token);

                // 跳转到首页
                this.$router.replace({path: '/admin/index'});
              } else {
                var msg = response.data.msg;
                this.$message({
                  type: 'warning',
                  message: msg,
                });
                this.loading = false;
              }
            } else {
              this.$message({
                type: 'warning',
                message: '请求异常：' + response.status,
              });
              this.loading = false;
            }
          }).catch(error => {
            console.log(error)
            this.loading = false;
          })
        }
      })
    }
  },
  mounted() {
    if(this.codeType=='math'){
      this.codeHolder='请输入计算后的值';
      this.rules.code[0].message='请输入计算后的值';
    }
    this.getCode();
    // 获取系统变量信息
    variableApi.queryVarNumVariable({varNum: "company"}).then(response => {
      if(response.data.code == 1) {
        this.data.company = response.data.data.company;
      }

    })

    variableApi.queryVarNumVariable({varNum: "cemetery"}).then(response => {
      if(response.data.code == 1) {
        this.data.cemetery = "("+response.data.data.cemetery+")";
      }

    })

    variableApi.queryVarNumVariable({varNum: "sysName"}).then(response => {
      if(response.data.code == 1) {
        this.data.sysName = response.data.data.sysName;
      }
    })

    //获取系统变量-logo
    variableApi.queryVarNumVariable({varNum: "logo"}).then(res => {
      if (res.data.code == 1) {
        this.logoPath = config.server + res.data.data.logo;
      }
    })
    this.$store.commit('setUnitInfo', this.data);// 放到全局变量中
    console.log("data: ", this.data )
  }
}
</script>
<style scoped>
.login-bg-img {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.login {
  margin: 0 auto;
  width: 330px;
  padding-top: 100px;
}

.login-code {
  padding-left: 2px;
  width: 32%;
  height: 41px;
  float: right;
}
.login-code-img {
  cursor: pointer;
  vertical-align: middle;
  height: 41px;
}

.logo {
  text-align: center;
  /*margin-bottom: 20px;*/
}
.title{
  display: inline;
  vertical-align: middle;
}
.title img {
  /*width: 40px;*/
  height: 40px;
  padding-bottom: 10px;
  margin-right: 3px;
}

.logo span{
  font-size: 25px;
  /*font-weight: bold;*/
  color: #fff;
}
.logo .cateway{
  font-size: 14px;
}

.sub-title {
  color: #fff;
  /*padding-top: -10px;*/
  font-size: 14px;
}

.label-color .el-form-item__label {
  color: #fff;
}
.footer{
  text-align: center;
  color: white;
  position: fixed;
  width: 100%;
  bottom: 10px;
  font-size: 14px;
}

</style>